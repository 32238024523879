@mixin lux__button-base(){
  position: relative;
  display: inline-block;
  cursor: pointer;
}

@mixin lux__button($bg_color: #00aed6, $text_color: #ffffff){
  @include lux__button-base;
  border: 1px solid transparent;
  padding: $lux_base;
  font-size: $lux_base;
  background: $bg_color;
  color: $text_color;
  border-radius: $lux_base * 0.2;

  &:hover {
    color: $bg_color;
    background: $text_color;
  }

  &:focus {
    outline-color: $text_color;
  }
}

@mixin lux__button--small(){
  padding: $lux_base / 2;
  font-size: $lux_base * 0.8;
}

@mixin lux__button--icon(){
  display: flex;
  justify-content: center;
  align-items: center;
}