@mixin cover_abs {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@mixin bg($size: cover, $position: center, $repeat: no-repeat) {
  background-size: $size;
  background-position: $position;
  background-repeat: $repeat;
}

@mixin overlay_content {
  @include cover_abs;
  content: "";
  display: block;
  pointer-events: none;
}

@mixin overlay($color: #000, $opacity: 0.3) {
  &::after {
    @include overlay_content;
    background: $color;
    opacity: $opacity;
  }
}

@mixin overlay--before($color: #000, $opacity: 0.3) {
  &::before {
    @include overlay_content;
    background: $color;
    opacity: $opacity;
  }
}

@mixin pattern($url: $pattern-1, $opacity: 1, $background: "transparent"){
  &::after {
    @include overlay_content;
    @include bg-pos--contain-repeat;
    background-color: $background;
    opacity: $opacity;
    background-image: $url;

    @at-root .no_pattern & {
      display: none;
    }
  }
}