$body-background: #fefefe;

$footer-background: #06143A;
$footer-text-color: #fff;
$footer-link-color: #fff;

$color-bg-accent: #efefef;
$color-text-root: #222;
$color-text-root-accent: #555;

$color-primary: #293382;
$color-secondary: #BCA070;
$color-tertiary: #F6E3D2;

$pattern-1: url("/images/default/patterns/pattern-1.svg");
$pattern-2: url("/images/default/patterns/pattern-2.svg");
$pattern-3: url("/images/default/patterns/pattern-3.svg");
$pattern-4: url("/images/default/patterns/pattern-4.svg");

$basic-font: 'Montserrat', sans-serif;
$special-font: 'Ogg', serif;