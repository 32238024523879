@mixin heading {
  font-family: $special-font;
  font-weight: normal;
  line-height: 1.2;
}

@mixin h1 {
  @include heading;
  font-size: 4rem;
  margin: 1.5rem 0 2rem;

  @media (max-width: 768px){
    font-size: 2.5rem;
  }
}

@mixin h1--huge {
  @include h1;
  font-size: 5rem;

  @media (max-width: 768px){
    font-size: 3rem;
  }
}

@mixin h1--enormous {
  @include h1;
  font-size: 5.5rem;

  @media (max-width: 768px){
    font-size: 3.5rem;
  }
}

@mixin h2 {
  @include heading;
  font-size: 2.5rem;
  margin: 1.5rem 0 2rem;

  @media (max-width: 768px){
    font-size: 2rem;
  }
}

@mixin h3 {
  @include heading;
  font-size: 2rem;
  margin: 1.5rem 0 1.5rem;

  @media (max-width: 768px){
    font-size: 1.5rem;
  }
}

@mixin h4 {
  @include heading;
  font-size: 1.6rem;
  margin: 1rem 0 1rem;

  @media (max-width: 768px){
    font-size: 1.2rem;
  }
}

@mixin h5 {
  @include heading;
  font-size: 1.4rem;
  margin: 1rem 0 1.5rem;
  
  @media (max-width: 768px){
    font-size: 1.2rem;
  }
}

@mixin h6 {
  @include heading;
  font-size: 1.2rem;
  margin: 1rem 0 1rem;

  @media (max-width: 768px){
    font-size: 1rem;
  }
}

@mixin p {
  font-size: 1rem;
  line-height: 1.2;
}

@mixin p--medium {
  font-size: 1.125rem;
  line-height: 1.2;
}

@mixin p--big {
  font-size: 1.25rem;
  line-height: 1.2;
}

@mixin p--large {
  font-size: 1.5rem;
  line-height: 1.2;
}

@mixin intro-text {
  font-size: 2rem;
  text-align: center;
  line-height: 1.2;
  font-family: $special-font;
}

@mixin quote {

}

@mixin special-underline {
  position: relative;
  padding-bottom: 5px;
  text-decoration: none;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: $color-secondary;
  }
}

@mixin special-underline--primary {
  &::after {
    background-color: $color-primary;
  }
}

@mixin special-underline--animated {
  @include special-underline;

  &::after {
    width: 0;
    transition: 0.3s ease;
  }

  &:hover{
    &::after {
      width: 100%;
    }
  }
}