@mixin bg-pos {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin bg-pos--contain-repeat($size: 600px) {
  background-size: $size;
  background-position: center;
  background-repeat: repeat;
}

@mixin bg-pos--left {
  @include bg-pos;
  background-position: left center;
}
@mixin bg-pos--left-top {
  @include bg-pos;
  background-position: left top;
}
@mixin bg-pos--left-bottom {
  @include bg-pos;
  background-position: left bottom;
}

@mixin bg-pos--right {
  @include bg-pos;
  background-position: right center;
}
@mixin bg-pos--right-top {
  @include bg-pos;
  background-position: right top;
}
@mixin bg-pos--right-bottom {
  @include bg-pos;
  background-position: right bottom;
}

@mixin bg-abs-cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  @include bg-pos;
}